import React from 'react'
import renderBloks from '@renderBloks'
import SbEditable from 'storyblok-react'
import PageContainer from '@system/page-container'
import ContentContainer from '@system/content-container'

const LifecyclePhasesModule = (props) => {
  return (
    <SbEditable content={props.blok}>
      <PageContainer>
        <ContentContainer>{renderBloks(props.blok.phases)}</ContentContainer>
      </PageContainer>
    </SbEditable>
  )
}

export default LifecyclePhasesModule
